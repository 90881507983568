// Color Utilities

.color-white { color: $white; }
.color-gray1 { color: $gray-100; }
.color-gray2 { color: $gray-200; }
.color-gray3 { color: $gray-300; }
.color-gray4 { color: $gray-400; }
.color-gray5 { color: $gray-500; }
.color-gray6 { color: $gray-600; }
.color-gray7 { color: $gray-700; }
.color-gray8 { color: $gray-800; }
.color-gray9 { color: $gray-900; }
.color-black { color: $black; }

.bg-color-white { background-color: $white; }
.bg-color-gray1 { background-color: $gray-100; }
.bg-color-gray2 { background-color: $gray-200; }
.bg-color-gray3 { background-color: $gray-300; }
.bg-color-gray4 { background-color: $gray-400; }
.bg-color-gray5 { background-color: $gray-500; }
.bg-color-gray6 { background-color: $gray-600; }
.bg-color-gray7 { background-color: $gray-700; }
.bg-color-gray8 { background-color: $gray-800; }
.bg-color-gray9 { background-color: $gray-900; }
.bg-color-black { background-color: $black; }

.color-pink { color: $pink; }
.color-orange { color: $orange; }
.color-yellow { color: $yellow; }
.color-green { color: $green; }
.color-lt-blue { color: $lt-blue; }
.color-lt-pink { color: $lt-pink; }

.bg-color-pink { background-color: $pink; }
.bg-color-orange { background-color: $orange; }
.bg-color-yellow { background-color: $yellow; }
.bg-color-green { background-color: $green; }
.bg-color-lt-blue { background-color: $lt-blue; }
.bg-color-lt-pink { background-color: $lt-pink; }