.virtual-appointment {
  .container {
    width: 100%;
  }
  .hero {
    background-color: #f5e9eb;
    background-image: url("/images/va-hero-mobile.jpg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    display: flex;
    margin-top: 0;
    height: 360px;
    @media (min-width: $media-breakpoint-md) {
      background-image: url("/images/va-hero-desktop.jpg");
      height: 325px;
      background-size: contain;
      background-position: bottom center;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 3.4375rem;
    }
    h1 {
      &:not(strong) {
        font-weight: 300;
      }
      text-align: center;
      margin-top: 3.4375rem;
      strong {
        font-family: $font-family-sans-serif;
        font-weight: 600;
        display: block;
      }
      @media (min-width: $media-breakpoint-md) {
        font-size: 1.5rem;
        margin-top: 0;
        text-align: left;
        strong {
          display: inline;
        }
      }
    }
  }
  
  .name-row {
    grid-gap: 0.7rem;
  }
  .fp-date-input .input-group-addon.cal {
    width: 100%;
  }

  
  .virtual-appointment-info {
    padding: 40px 5px;
    flex: 1 1 auto;
    max-width: 500px;
    width: 100%;
    margin-left: 80px;
    @media (max-width: $media-breakpoint-md) {
      max-width: 405px;
      margin: 0 auto;
      padding: 15px;
    }


    .margin-bottom-xs{
      margin-bottom: -13px;
    }
    .h1-light-desktop-largest {
      margin-bottom: 28px;
    }    
    .text-muted {
      font-style: italic;
      color: #595959;
      font-size: 0.875rem;
    }
    h2 {
      margin-top: 0;
    }
    p {
      max-width: 310px;
    }
    .steps {
      padding-top: 20px;
      .number {
        position: absolute;
        font-size: 1.3125rem;
        width: 52px;
        height: 52px;
        border: 2px solid #f0f1f4;
        border-radius: 50%;
        text-align: center;
        left: -80px;
        top: -18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h6 {
        position: relative;
        margin-bottom: -10px;
      }
      p {
        text-align: left;
        max-width: 270px;
      }
      .step {
        padding-left: 5rem;
        margin-bottom: 3.75rem;
      }
    }
  }
  .virtual-appointment-form {
    padding: 55px 15px;
    display: flex;
    justify-content: center;
    background-color: #f0f1f4;
    @media (min-width: $media-breakpoint-md) {
      padding: 75px 0;
    }
    .form-wrapper {
      max-width: 375px;
      width: 100%;
    }
    .lets-get-started {
      margin: 0 0 45px 0;
      @media (min-width: $media-breakpoint-md) {
        text-align: left;
        margin: 0 0 50px 0;
      }
    }
 
    .captcha-wrapper {
      padding-top: 24px;
      display: flex;
      justify-content: center;
    }
    .submit-wrapper {
      display: flex;
      margin-top: 30px;
      .btn {
        width: 100%;
      }
    }
  }
}
