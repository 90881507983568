html {
  scroll-behavior: smooth;
}
.designer-directory-hero {
  background-image: url("../../public/images/designer-directory/Designer_Directory_2X.jpg");
  // width: 90%;
  padding-top: 22%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 768px) {
    background-image: url("../../public/images/designer-directory/Designer_Directory_Main_Desktop.png");
    background-color: #e4e3e8;
    padding-top: 390px;
    background-size: 550px;
    background-position: center;
    padding-right: 50px;
    margin: auto;
    margin-bottom: -35px;
  }
  @media (max-width: 630px) {
    background-size: 500px;
    margin-bottom: -60px;
  }
  @media (max-width: 500px) {
    background-size: 450px;
    margin-bottom: -60px;
  }
  @media (max-width: 430px) {
    background-size: 400px;
  }
  @media (max-width: 375px) {
    background-size: 350px;
  }
  @media (max-width: 330px) {
    background-size: 300px;
  }

  h1 {
    margin-top: 50px;
    position: relative;
    margin: auto;
    padding-left: 35px;
    max-width: 1280px;

    @media (max-width: 1800px) {
      bottom: 200px;
    }
    @media (max-width: 1550px) {
      bottom: 170px;
    }
    @media (max-width: 1350px) {
      bottom: 150px;
    }
    @media (max-width: 1280px) {
      bottom: 140px;
    }
    @media (max-width: 1070px) {
      bottom: 120px;
    }
    @media (max-width: 950px) {
      bottom: 100px;
    }
    @media (max-width: 768px) {
      bottom: 360px;
      font-size: 18px;
      margin: auto;
      padding-left: 60px;
      width: fit-content;
    }
    @media (max-width: 565px) {
      padding-left: 20px;
    }
    @media (max-width: 371px) {
      font-size: 15px;
      padding-top: 10px;
    }
  }
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  background-color: #f8f8f8;
  width: 100%;
  height: 60px;
  position: -webkit-sticky;
  position: sticky;
  top: 62px;
  text-align: center;
  padding-top: 0;

  @media (max-width: 995px) {
    top: 0px;
  }

  @media (min-width: 1280px) {
    height: 65px;
    text-align: center;
    width: 100vw;
    margin: 0 calc(-1 * (100vw - 100%) / 2);
    overflow: hidden;
    font-size: 18px;
    margin-top: -16px;
    padding-top: 3px;
  }

  .card {
    background-color: transparent;
    border-color: transparent;
    display: inline-block;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 15px;
    font-weight: 600;
    margin: 20px 25px 0 18px;
    text-decoration: none;
    color: #222;
    @media (max-width: 1280px) {
      margin: 22px 12px 0 12px;
    }
    @media (max-width: 980px) {
      margin: 22px 8px 0 8px;
    }
    @media (max-width: 825px) {
      margin: 22px 5px 0 5px;
    }

    span {
      position: relative;
      left: 7px;
    }
  }
  .card:hover,
  .card:focus {
    color: inherit;
    border-bottom: 1px solid #000000;
    text-decoration: none;
  }
}
.designer-directory-container {
  // width: 80%;
  @media (max-width: 1280px) {
    // width: 90%;
  }
  @media (max-width: $media-breakpoint-sm) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.designers-list {
  width: auto;
  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .letter-row {
    @media (max-width: 767px) {
      margin-bottom: 8px;
    }
  }
  h1 {
    font-size: 30px;
    margin: 0;
  }
  .alpha-header-rule {
    border-left: none;
    position: relative;
    top: -24px;
    margin: 0 -15px -18px 50px;
    border: 1px solid #bcbcbc;
    border-bottom: none;
    @media (max-width: 767px) {
      width: 90%;
    }
    @media (max-width: 400px) {
      width: 80%;
    }
  }
  .category-header-rule {
    display: none;

    @media (min-width: 425px) {
      display: block;
      border-left: none;
      border-top: none;
      margin: 25px -30px 30px 35px;
      border-color: #bcbcbc;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .anchor-buffer {
    height: 0;
    position: relative;
    top: -175px;
    @media (min-width: 768px) {
      top: -125px;
    }
    @media (min-width: 950px) {
      top: -150px;
    }
  }
  .brand-category-header {
    font-size: 12px;
    letter-spacing: 2.33px;
    margin: 0 0 16px 0;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
  .brand-category-items {
    margin-bottom: 2px;
  }
  .brand-name {
    margin-top: 0;
    margin-bottom: 0;
    @media (min-width: 768px) {
      margin-left: 50px;
      padding-right: 20px;
    }
    a {
      color: #222;
    }
    a:hover,
    a:focus {
      color: inherit;
      border-bottom: 1px solid #000000;
      text-decoration: none;
    }
  }
  .designer-sell-btn {
    display: block;
    margin: 0 0 53px 0;
    width: 100%;
    button {
      width: 100%;
    }
    @media (min-width: 768px) {
      margin: 6px -10px 35px 0;
      width: 220px;
      float: right;
    }
  }

  .col-xs-12 {
    padding: 0;
  }
  .h-25 {
    height: 25px;
    @media (min-width: 768px) {
      height: 0;
    }
  }
  .h-45 {
    height: 45px;
  }
  .item-name {
    font-weight: 600;
    text-decoration: none;
  }
}
