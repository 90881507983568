.white-glove {
  .container {
    @media (max-width: $media-breakpoint-md) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .padding-bottom-max,
  .padding-top-max {
    @media (max-width: $media-breakpoint-md) {
      padding-top: 0;
      padding-bottom: 30px;
    }
  }
  .text-wrapper {
    margin-right: 30px;
    @media (max-width: $media-breakpoint-md) {
      margin-right: 0;
      margin-top: 26px;
    }
  }
  .flex-wrapper {
    display: flex;
    flex-direction: column;
    @media (max-width: $media-breakpoint-md) {
      flex-direction: column-reverse;
    }
  }
  .fix-width-row {
    @media (max-width: $media-breakpoint-md) {
      max-width: 490px;
      margin: auto;
    }
  }
  .bg-lt-gray {
    background-color: $lt-blue;
  }

  .fp-form-style {
    .address-row,
    .name-row {
      grid-gap: 0.7rem;
    }
  }

  .zip-error,
  .form-error {
    font-size: 0.75rem;
    font-family: $font-family-sans-serif;
    font-weight: 300;
    color: $danger;
    display: block;
    text-align: center;
  }

  .terms-and-cond {
    font-family: $font-family-sans-serif;
    font-weight: 500;
    color: $dark;
    text-decoration: underline;
    &:hover {
      color: $black;
    }
  }

  .submit-wrapper {
    margin: 1.25rem 0;
    .btn {
      width: 100%;
    }
  }
  .selling-experience {
    padding: 100px 0 47px 0;
    margin-bottom: 0;
    @media (max-width: $media-breakpoint-md) {
      padding: 66px 0 40px 0;
    }
    h2 {
      margin: 0 0 75px 0;
    }
    h4 {
      font-size: 0.875rem;
      font-family: $font-family-sans-serif;
      font-weight: 600;
      letter-spacing: 0.125rem;
      color: $dark;
      margin: 0 0 1.25rem 0;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .number {
        font-size: 1.875rem;
        line-height: 1;
        width: 72px;
        height: 72px;
        border: 2px solid #f0f1f4;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .steps {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      @media (min-width: $media-breakpoint-md) {
        flex-direction: row;
      }
      p {
        max-width: 260px;
        margin-right: auto;
        margin-left: auto;
        @media (max-width: $media-breakpoint-md) {
          margin-bottom: 55px;
        }
      }
    }
  }
  .what-buying {
    background-color: $dark;
    padding: 93px 0;
    color: $white;
    display: flex;
    justify-content: center;
    h4,
    h6 {
      color: $white;
    }
    h4 {
      margin-bottom: 37px;
    }

    .btn-outline-secondary {
      margin: 0 auto;
      width: 100%;
      max-width: 260px;
      display: block;
    }
  }





  

/*

  .wg-pickup-list {
    .bg-lt-gray {
      background-color: $lt-blue;
    }
    .wg-list-header {
      background-color: $lt-blue;
      margin-left: -15px;
      margin-right: -15px;
      padding: 3.125rem 1.875rem 1.875rem 1.875rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .fp-logo {
        height: 28px;
        font-size: 28px;
        margin-bottom: 1.25rem;
      }
 
      .view-print {
        width: 200px;
      }
    }
    .container {
      max-width: 768px;
    }
    .item-list {
      .add-item {
        width: 200px;
      }
    }
    .item-card {
      .card-header {
        padding: 1.5625rem 1.875rem 0.9375rem 1.875rem;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        h4 {
          font-family: $font-family-sans-serif;
          font-weight: 600;
          font-size: 0.625rem;
          line-height: 1;
          letter-spacing: 0.125rem;
          color: $dark;
          text-transform: uppercase;
        }
        .edit {
          a {
            color: #595959;
            font-size: 0.875rem;
            padding: 0 1.25rem;
            line-height: 1.45;
            border-right: 2px solid rgba(#979797, 0.5);
            &:hover {
              color: #595959;
              text-decoration: underline;
            }
            &:last-of-type {
              border-right: 0;
              padding-right: 0;
            }
          }
        }
      }
      .item-image {
        max-width: 100px;
      }
      .card-body {
        padding: 2.1875rem 1.875rem 0 1.875rem;
        font-size: 0.875rem;
        color: $dark;
        font-family: $font-family-sans-serif;
        font-weight: 300;
        line-height: 1.5;
        .flex-row {
          display: flex;
          flex-direction: row;
          margin-bottom: 3rem;
          .col-30 {
            flex: 1 1 30%;
          }
          .col-40 {
            flex: 1 1 40%;
          }
        }
        h5 {
          font-family: $font-family-sans-serif;
          font-weight: 400;
          font-weight: 400;
          font-size: 0.875rem;
          color: $dark;
          margin: 0 0 0.4375rem 0;
        }
      }
    }
    .form-wrapper {
      h3 {
        padding: 1.5625rem 1.875rem 0.9375rem 1.875rem;
        background-color: $white;
        font-size: 0.875rem;
        font-family: $font-family-sans-serif;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
        color: $dark;
        margin: 0;
      }
      .customer-info {
        max-width: 460px;
        margin-left: 1.875rem;
        padding: 2.5rem 0 5rem 0;
      }

      .sig-pad-wrapper {
        margin-left: 1.875rem;
        margin-right: 1.875rem;
        .signature-pad {
          width: 100%;
          height: 200px;
          background-color: $white;
        }
        .sig-buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 1.875rem;
          margin-bottom: 3.125rem;
          & > button {
            width: 200px;
            margin-left: 0.9375rem;
          }
        }
      }
    }
  }
 */
}
