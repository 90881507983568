.skeleton {
  background-color:$gray-400;
  margin: 10px 0;
  animation-name: skeletonAnimation;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes skeletonAnimation {
  0% {
    background-color: $gray-200;
  }
  50% {
    background-color: $gray-300;
  }
  100% {
    background-color: $gray-400;
  }
  100% {
    background-color: $gray-200;
  }
}

.skeleton.text {
  width: 100%;
  height: 100px;
  margin-top: -5px;
}
.skeleton.body-address {
  width: 100%;
  height: 120px;
}
.skeleton.body-times {
  width: 100%;
  height: 330px;
}
.skeleton.title {
  width: 30%;
  height: 20px;
  margin-bottom: 15px;
}
.skeleton.map {
  margin-top: 40px;
  width: 100%;
  height: 500px;
}

// services
.skeleton.services-title {
  width: 25%;
  height: 20px;
  margin-bottom: 32px;
}
.skeleton.services-photo {
  width: 100%;
  height: 250px;
}
.skeleton.services-body {
  width: 100%;
  height: 100px;
}
.skeleton.services-title-sm {
  width: 30%;
  height: 20px;
  margin-bottom: 15px;
}

// additional stores
.skeleton.addl-title {
    width: 10%;
    height: 20px;
    margin-bottom: 32px;
  }
  .skeleton.addl-body {
    width: 100%;
    height: 165px;
  }