.bg-lt-gray {
  background-color: $lt-blue;
}

.d-none {
  display: none;
}

.img-fluid, .img-thumbnail {
  max-width: 100%;
  height: auto;
}

.visible-xs {
  @media (max-width: $media-breakpoint-sm) { 
    display: block;
  }
  @media (min-width: $media-breakpoint-sm+1) { 
    display: none;
  }
}

.hidden-xs {
  @media (max-width: $media-breakpoint-sm) { 
    display: none;
  }
  @media (min-width: $media-breakpoint-sm+1) { 
    display: block;
  }
}