.submission-modal [aria-labelledby="customModalTitle"] {
  width: 21.7rem !important;
}

[aria-labelledby="customModalTitle"] {
  @media (max-width: $media-breakpoint-sm) {
    width: 100% !important;
  }
}

.image-details-modal button {
  margin-top: 15px !important;
}

.modal-header {
  padding-top: 15px;
}

.image-details-modal {
  .modal-header {
    margin-top: -15px;
  }
}

.item-details-modal {
  .modal-header {
    margin-top: -15px;
  }
}
