.fw-sb { font-weight: $font-weight-semibold !important; }
.fw-md { font-weight: $font-weight-medium !important; }
.fw-lt { font-weight: $font-weight-light !important; }

p, li {
    font-size: 0.9325rem;
    font-weight: $font-weight-light;
}
p {
  @extend .body-lg;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}

p,
ul {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
    &:hover {
      color: $dark;
      text-decoration: none;
    }
  }
}

h1 {
  @extend .h1;
}
h2 {
  @extend .h2;
}
h3 {
 @extend .h3;
}
h4 {
  @extend .h4;
}
h5 {
  @extend .h5;
}
h6 {
  @extend .h6;
}