@import "@fashionphile/component-library/Styles/theme.module.scss";

@import "fonts";
@import "variables";
//@import "~bootstrap/scss/bootstrap";

@import "base/typography";
@import "base/utility";
@import "base/layout";
@import "base/buttons";
@import "base/forms";
@import "base/modals";

@import "components/image_uploader";
@import "components/datepicker";
@import "components/item-survey";
@import "components/loadingStates";
@import "components/MainHeader";

@import "pages/sell-with-us";
@import "pages/sell-your-bag-v2";
@import "pages/virtual-appointment";
@import "pages/white-glove";
@import "pages/designer-directory";
@import "pages/locations";

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    font-family: "Metropolis", Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #000;
}
//discrepancy between figma spec and Comp library
.body-lg {
    font-weight: 300;
}