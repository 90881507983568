.fp-date-input {

 .input-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      input.form-control {
        &.date {
          background-color: white;
          outline: none;
          &:focus {
            border-bottom: none;
          }
 
        }
      }
      select.form-control {
        padding: 5px 5px 5px 52px;
        width: 100%;
      }
    }
    .input-group-addon {
      border: 0;
      background-color: transparent;
      color: $dark;
      padding: 0;
      &.cal {
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        background-color: white;
        height: 52px;
        padding-bottom: 4px;
        padding-left: 17px;
      }
      &.clock {
        margin-right: 30px;
        position: absolute;
        z-index: 10;
        left: 16px;
      }
      &.tz {
        position: absolute;
        z-index: 10;
        left: 130px;
        font-size: 1rem;
      }
    }
    select.form-control {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48cGF0aCBmaWxsPSJyZ2IoMTI4LDEyOCwxMjgpIiBkPSJNMzEuMyAxOTJoMjU3LjNjMTcuOCAwIDI2LjcgMjEuNSAxNC4xIDM0LjFMMTc0LjEgMzU0LjhjLTcuOCA3LjgtMjAuNSA3LjgtMjguMyAwTDE3LjIgMjI2LjFDNC42IDIxMy41IDEzLjUgMTkyIDMxLjMgMTkyeiIvPjwvc3ZnPg==)
        no-repeat 98% 50%;
      background-size: 10px;
      background-color: $white;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      &[disabled] {
        background-color: $white;
        font-style: italic;
        color: #cccccc;
      }
      &:focus {
        box-shadow: none;
        border-color: transparent;
        border-bottom-color: $black;
      }
      &.false {
        cursor: not-allowed;
      }
      &.true {
        outline: none;
        cursor: pointer;
      }
    }
    #date-picker-inline {
        width: auto;
    }
    .form-group {
      margin-bottom: 0.625rem;
    }
    .datepicker-tooltip span{
      z-index: 100;
    }
    .control-label {
        display: block;
        margin-bottom: 0.25rem;
        font-weight: 200;
        font-size: 0.9375rem;
        line-height: 1.7em;
        font-family: "Metropolis", "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
      input[type="text"],
      input[type="email"],
      input[type="phone"],
      input[type="password"],
      select {
        &.form-control {
            border-color: transparent;
            color: #191c1f;
            font-size: 1rem;
            font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-weight: 300;
            line-height: 1.375;
            height: 52px;
            padding: 0.625rem 0.875rem;
            box-shadow: none;
            width: 100%;
          &:focus {
            box-shadow: none;
            border-color: transparent;
            border-bottom-color: $black;
          }
        }
      }
      select.form-control {
        margin-top: -2px;
      }
    }