.header {
  .rotatorSvgFix,
  .searchSvgFix {
    svg {
      vertical-align: initial;
    }
  }
  .mobileHeaderFix {
    > div > div {
      div:last-child {
        a:first-child {
          top: 0.1rem;
        }
      }
    }
  }
  span {
    div:first-child {
      box-sizing: border-box !important;
    }
  }

  .excerpt {
    font-size: $font-size-sm;
    margin-bottom: $padding-sm;
    .bold {
      font-weight: $font-weight-bold;
    }
    a {
      font-weight: $font-weight-bold;
    }
  }
}
