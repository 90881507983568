#request-received-panel {
  .fp-form-style {
    margin-top: 36px;
  }
  .thank-you {
    padding-bottom: 2.5rem;
  }
  .add-item {
    display: inline-flex;
    font-size: 0.875rem;
    font-family: $font-family-sans-serif;
    font-weight: 300;
    align-items: center;
    justify-content: flex-start;
    margin: 1.875rem 0;
    .icon-wrapper {
      display: inline-block;
      width: 28px;
      height: 28px;
      border: 1px solid $black;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
  }
  .remove-item {
    text-align: right;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    color: $dark;
    font-size: 0.75rem;
    margin-top: -1.5rem;
    @media (max-width: $media-breakpoint-sm) {
      margin-top: -1rem;
    }
    a {
      color: $dark;
      &:hover {
        color: $dark;
        text-decoration: underline;
      }
    }
  }
  .appointment-item {
    margin-bottom: 1.875rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    h6 {
      margin: 0 0 15px 0;
    }
  }

  .form-wrapper {
    display: none;
    width: 100%;
    max-width: 460px;
    margin: auto;
    &.active {
      display: block;
      animation: fadeIn 1s;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
}

.verified-icon {
  background-color: $black;
  font-size: 1.25rem;
  color: $white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}