.image-uploader {
  .row {
    grid-gap: 1rem;
    @media (max-width: 575px) {
      grid-gap: 0.5rem;
    }
  }
  .addPhotoDesktop {
    @media (max-width: 575px) {
      display: none;
    }
  }
  .camera {
    @media (max-width: 575px) {
      margin: -8px;
      margin-left: 10px;
    }
  }
  .custom-column {
    grid-column: span 3;
    @media (min-width: $media-breakpoint-md + 1) and (max-width: $media-breakpoint-lg) {
      grid-column: span 4;
    }
    @media (min-width: $media-breakpoint-sm + 1) and (max-width: $media-breakpoint-md) {
      grid-column: span 4;
    }
    @media (min-width: $media-breakpoint-xs + 1) and (max-width: $media-breakpoint-sm) {
      grid-column: span 3;
    }
    @media (max-width: $media-breakpoint-xs) {
      grid-column: span 4;
    }
  }

  .p-2 {
    padding: 0 !important;
  }
  .box {
    height: 100px;
    width: 100px;
    position: relative;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px dashed #35383a;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
 
    &.addPhotoMobile {
      height: 52px;
      width: auto;
      background: #fff;
      font-family: Metropolis;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      border: #35383a solid 1px;
      float: none;
      margin: 16px auto;
      @media (min-width: 577px) {
        display: none;
      }
      svg {
        position: relative;
        top: 5px;
        left: -15px;
      }
    }
    &.solidBorder {
      background: #fff;
      border: 1px solid #d8d8d8;
      &.error {
        border: 1px solid $pink;
        z-index: 2;
      }
    }
    &.solidBorder-add-photo {
      border: 1px solid #35383a;
      background: #fff;
    }
    img {
      position: relative;
      display: block;
      max-width: 95%;
      min-width: 50%;
      max-height: 95%;
      flex: none;
      order: 0;
      flex-grow: 0;
      z-index: 1;
    }
    .color-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      opacity: 0.6;
      border-radius: 4px;
      z-index: 2;
    }
    .camera {
      background-position: center center;
      margin-bottom: 4px;
      width: 24px;
    }
    .done {
      position: absolute;
      top: 8px;
      right: 8px;
      border-radius: 25px;
      background: #000;
      width: 16px;
      height: 16px;
      z-index: 4;
      svg {
        position: relative;
        top: -1px;
      }
    }
    .loading {
      width: 100%;
      position: absolute;
      top: -15px;
      left: 0px;
      opacity: 0.7;
      background-size: 75% 75%;
      background-position: center;
      z-index: 4;
    }
    span.caption {
      width: 96.25px;
      font-family: Metropolis;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      align-items: center;
      display: inline-block;
      color: #35383a;
      flex: none;
      order: 0;
      flex-grow: 0;
      @media (max-width: $media-breakpoint-lg) {
        font-size: 10.42px;
        line-height: 17px;
      }
      padding: 0 5px;
    }
  }
  .icon-error-container {
    .icon-error {
      width: 32px;
      height: 28.44px;
      position: absolute;
      z-index: 3;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    }
  }

  .row.error {
    svg {
      display: inline-block;
      width: 16px;
      height: 14.22px;
    }
    .icon {
      display: inline-block;
      margin: 10px 10px -1px 0;
    }
    .caption {
      font-size: 12.5px;
      line-height: 20px;
      display: inline-block;
      color: $pink;
      margin: 6px 0 0 0;
    }
  }
}
