.custom-form-group {
  .custom-textarea-input {
    font-family: "Metropolis", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 100%;
    height: 150px;
    border: 0.0625rem solid #d8d8d8;
    outline: none;
    font-weight: 200;
    font-size: 0.9375rem;
    line-height: 1.7em;
    padding: 1rem;
    &.error {
      border: 0.0625rem solid $color-pink;
    }
    &.disabled {
      background-color: $color-grey-1;
    }
    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s,
        background 0s ease 0s;
      transition: width 0.3s ease 0s, left 0.3s ease 0s, background 0s ease 0s;
      border-color: transparent transparent #000;
      border-bottom-width: 2px;
      overflow: hidden;
      outline: none;
      .underline {
        width: 100%;
      }
    }
  }

  &.error {
    .underline {
      background: $color-pink;
    }
    &.focus {
      .underline {
        transition: width 0.3s ease 0s, left 0.3s ease 0s, background 0s ease 0s;
      }
    }
  }
  .custom-label {
    font-family: "Metropolis", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-display: swap;
    font-weight: 200;
    font-size: 0.9375rem;
    line-height: 1.7em;
    display: block;
    max-width: fit-content;
    overflow-wrap: normal;
    margin-bottom: 0.25rem;
    &.error {
      color: $color-pink;
    }
  }
  // triangle error block
  .help-block {
    font-family: "Metropolis", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 200;
    font-size: 0.78125rem;
    line-height: 1.7em;
    display: block;
    &.error {
      color: $color-pink;
    }
    i {
      padding-right: 7px;
      font-size: 14px;
    }
  }
  .inputBase {
    display: flex;
    .input {
      @include body-lg;
      border: none;

      outline: none;
      height: 3.25rem;
      width: 100%;
      padding-left: $padding-sm;
      padding-right: $padding-sm;

      &.left {
        &.primary {
          padding-left: $padding-xxl;
        }
        &.secondary {
          padding-left: $padding-xl;
        }
        &.ghost {
          padding-left: $padding-xxl;
        }
      }

      &:disabled {
        background-color: $color-grey-1;
        cursor: not-allowed;
      }

      &.error {
        color: $color-pink;
      }
    }
  }

  .underline {
    background: none repeat scroll 0 0 transparent;
    content: "";
    display: block;
    height: 0.125rem;
    position: absolute;
    bottom: 0rem;
    background: $color-black;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0rem;
    z-index: 1;
  }
}

//fixes CL bug of missing bottom bordere
form span {
  box-sizing: content-box;
}