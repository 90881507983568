.sell-with-us {
  .read-more {
    cursor: pointer;
  }
  .many-ways {
    background-color: #f0efef;
    background-image: url("/images/selling_mobile_header.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    height: 18.75rem;
    display: flex;
    align-items: flex-end;
    @media (min-width: $media-breakpoint-md) {
      background-color: #f1f1f1;
      background-image: none;
      align-items: center;
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/images/selling_desktop_header.jpg");
        background-size: auto 100%;
        background-position: top center;
        background-repeat: no-repeat;
        z-index: 2;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 41px;
        background-color: $white;
        z-index: 1;
      }
      & > div {
        z-index: 3;
      }
    }
    h1, h6 {
     @media (max-width: $media-breakpoint-md) {
        text-align: center;
      }
    }
    h1 {
      margin:  10px 0px 34px;
    }
    h6 {
      margin: 0;
    }
  }
  .selling-methods {
    h2 {
      font-family: $font-family-sans-serif;
      font-weight: 600;
      text-align: center;
      color: $cinder;
      font-size: 0.875rem;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      margin: 2.1875rem 0 0.9375rem 0;
    }
    .d-flex {
      display: flex;
      flex-direction: column;
      .option {
        background-color: $lt-blue;
        text-align: center;
        padding: 3.125rem 1.25rem 1.25rem 1.25rem;
        margin-bottom: 0.9375rem;
        .icon {
          margin-bottom: 2.1875rem;
        }
        h3 {
          font-family: $font-family-sans-serif;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.5;
          color: $cinder;
          letter-spacing: 0.125rem;
          margin: 0 0 0.625rem 0;
          text-transform: uppercase;
        }
        p {
          font-size: 1rem;
          font-family: $font-family-sans-serif;
          font-weight: 300;
          color: $cinder;
          margin: 0;
          line-height: 1.5;
          em {
            font-size: 0.875rem;
          }
        }
        .read-more {
          font-size: 1rem;
          line-height: 1.5;
          font-family: $font-family-sans-serif;
          font-weight: 500;
          display: inline-block;
          color: $cinder;
          text-transform: none;
          letter-spacing: normal;
          text-decoration: underline;
          margin-bottom: 2.5rem;
          padding: 0;
        }
        .btn-cta {
          width: 100%;
          white-space: nowrap;
        }
        &.most-popular {
          position: relative;
          .popular-tag {
            font-family: $font-family-sans-serif;
            font-weight: 600;
            letter-spacing: 0.125rem;
            position: absolute;
            text-transform: uppercase;
            color: $cinder;
            font-size: 0.75rem;
            line-height: 1.5;
            padding: 0.75rem 0.9375rem;
            background-color: #c2d1e7;
            top: 0;
            left: 0;
          }
        }
      }
    }

    @media (min-width: $media-breakpoint-md) {
      h2 {
        text-align: left;
      }
      .d-flex {
        flex-direction: row;
        flex-wrap: wrap;
        padding-right: 15px;
        .option {
          flex: 1 1 calc(50% - 15px);
          margin-right: 15px;
          max-height: 21.875rem;
          height: 21.875rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding: 0 0 2.8125rem 0;
          .icon {
            height: 130px;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .btn-cta {
            max-width: 275px;
            margin-top: auto;
          }
          p {
            font-size: 0.875rem;
            max-width: 400px;
            em {
              font-size: 0.75rem;
            }
          }
          .read-more {
            font-size: 0.875rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .free-shipping-banner {
    h2 {
      font-size: 0.6875rem;
      letter-spacing: 0.125rem;
      font-family: $font-family-sans-serif;
      font-weight: 600;
      line-height: 2;
      text-align: center;
      text-transform: uppercase;
      color: $cinder;
      max-width: 15.625rem;
      margin: 0.3125rem auto 1.25rem;
      .shipping-icon {
        display: none;
      }
    }
    @media (min-width: $media-breakpoint-md) {
      h2 {
        max-width: none;
        font-size: 0.75rem;
        position: relative;
        margin: 2.8125rem 0 3.75rem 0;
        .shipping-icon {
          margin-left: 1.25rem;
          display: inline-block;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 20%;
          height: 1px;
          background-color: rgba($cinder, 0.5);
          top: 50%;
        }
        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }
      }
    }
  }
  .best-options {
    h3 {
      font-family: $font-family-sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      color: $cinder;
      margin: 2.1875rem 0 0.625rem 0;
    }
    h2 {
      font-family: $font-family-sans-serif;
      font-weight: 600;
      font-size: 1.125rem;
      letter-spacing: 0.1875rem;
      text-transform: uppercase;
      color: $cinder;
      line-height: 1.3;
      margin-top: 0;
      margin-bottom: 1.25rem;
    }
    .btn-cta button  {
      font-size: 12px;
    }
    .option-panel {
      padding-left: 15px;
      padding-right: 15px;
      .option-title {
        margin: 0;
        font-size: 0.875rem;
        line-height: 1;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        font-family: $font-family-sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        position: relative;
        &:before,
        &:after {
          content: "";
          position: absolute;
          background-color: $cinder;
          transition: transform 0.3s ease-out;
        }
        /* Vertical line */
        &:before {
          top: 1.25rem;
          width: 0.0625rem;
          height: 0.9375rem;
          right: 0.4375rem;
        }
        /* horizontal line */
        &:after {
          top: 1.6875rem;
          right: 0;
          width: 0.9375rem;
          height: 0.0625rem;
        }
      }
      .option-list {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        height: auto;
        & > div:first-of-type {
          margin-top: 0.9375rem;
        }
        h5 {
          font-size: 0.875rem;
          font-family: $font-family-sans-serif;
          font-weight: 500;
          color: $cinder;
          margin: 0 0 0.625rem 0;
        }
        p {
          font-size: 1rem;
          font-family: $font-family-sans-serif;
          font-weight: 300;
          color: $cinder;
          margin: 0 0 0.9375rem 0;
          line-height: 1.5;
        }
 
      }
      &:nth-child(odd) {
        background-color: #f8f8f9;
      }
      &.open {
        .option-title {
          &:before {
            transform: rotate(90deg);
          }
          &:after {
            transform: rotate(180deg);
          }
        }
        .option-list {
          max-height: 43.75rem;
        }
      }
    }
    @media (max-width: $media-breakpoint-md) {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media (min-width: $media-breakpoint-md) {
      margin-bottom: 5rem;
      h3 {
        text-align: center;
        margin: 5rem 0 0.625rem 0;
      }
      h2 {
        text-align: center;
        font-size: 1.25rem;
        margin-bottom: 1.875rem;
      }
      .options-wrapper {
        display: flex;
        justify-content: center;
      }
      .option-panel {
        max-width: 270px;
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
        .option-title {
          padding: 0 1.5625rem;
          line-height: 1.6;
          height: 3.125rem;
          margin: 2.5rem 0;
          &:before,
          &:after {
            display: none;
          }
        }
        .option-list {
          flex: 1 1 auto;
          max-height: initial;
          display: flex;
          flex-direction: column;
          & > div:first-of-type {
            margin-top: 0;
          }
          h5 {
            font-size: 0.75rem;
          }
          p {
            font-size: 0.75rem;
          }
          .cta {
            margin-top: auto;
            padding: 0;
          }
          & > div {
            padding-left: 1.5625rem;
            padding-right: 1.5625rem;
          }
          .submission {
            flex: 0 0 6.25rem;
          }
          .receiving {
            flex: 0 0 6.875rem;
          }
          .shipping {
            flex: 0 0 8.125rem;
          }
          .paid {
            flex: 0 0 9.375rem;
          }
          .perfect {
            flex: 0 0 8.75rem;
          }
        }
      }
    }
  }
  .selling-made-simple {
    h3 {
      font-size: 1.125rem;
      font-family: $font-family-sans-serif;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.1875rem;
      color: $cinder;
      text-transform: uppercase;
      margin: 2.1875rem 0 0.625rem;
    }
    p {
      font-size: 1rem;
      font-family: $font-family-sans-serif;
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: 2.1875rem;
      color: $cinder;
    }
    @media (max-width: $media-breakpoint-md) {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media (min-width: $media-breakpoint-md) {
      margin-bottom: 4.375rem;
      .col-left {
        padding-right: 3.125rem;
      }
      .col-right {
        padding-left: 2.1875rem;
      }
      h3 {
        font-size: 1.25rem;
        margin: 3.75rem 0 1.125rem;
      }
      p {
        max-width: 25rem;
        font-size: 0.875rem;
      }
    }
    .img-fluid {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
  .selling-tips {
    background-color: #f8f8f9;
    h3 {
      font-family: $font-family-sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.125rem;
      color: $cinder;
      text-transform: uppercase;
      margin: 2.5rem 0 0.625rem 0;
    }
    h2 {
      font-size: 1.125rem;
      font-family: $font-family-sans-serif;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.1875rem;
      color: $cinder;
      text-transform: uppercase;
      margin: 0 0 1.875rem 0;
    }
    p {
      font-size: 1rem;
      font-family: $font-family-sans-serif;
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: 2.1875rem;
      margin-top: 0;
      color: $cinder;
    }
    .tip {
      display: flex;
      h4.number {
        flex: 0 0 2.75rem;
        border: 0.03125rem solid $cinder;
        color: $cinder;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.125rem;
        text-align: center;
        margin: 0 0.9375rem 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
      }
    }
    @media (max-width: $media-breakpoint-md) {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media (min-width: $media-breakpoint-md) {
      padding-bottom: 1.5625rem;
      h3 {
        text-align: center;
        margin: 3.75rem 0 0.625rem 0;
      }
      h2 {
        text-align: center;
        font-size: 1.25rem;
        margin: 0 0 3.75rem 0;
      }
      p {
        text-align: center;
        font-size: 0.875rem;
      }
      .tips {
        display: flex;
        justify-content: space-around;
      }
      .tip {
        max-width: 260px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h4.number {
          flex: 0 0 4.5rem;
          width: 4.5rem;
          height: 4.5rem;
          font-size: 1.875rem;
          margin: 0 0 1.25rem 0;
        }
      }
    }
  }
  .payment-options {
    background-color: $cinder;
    color: $white;
    h2 {
      font-size: 1.125rem;
      font-family: $font-family-sans-serif;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.1875rem;
      color: $white;
      text-transform: uppercase;
      margin: 2.1875rem 0 1.25rem;
    }
    p,
    ul {
      font-size: 1rem;
      font-family: $font-family-sans-serif;
      font-weight: 300;
      color: $white;
    }
    p {
      margin-bottom: 1rem;
      line-height: 1.5;
    }
    ul {
      margin-bottom: 2.8125rem;
      line-height: 2.1;
    }
    @media (max-width: $media-breakpoint-md) {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media (min-width: $media-breakpoint-md) {
      padding-top: 3.75rem;
      .d-flex {
        display: flex;
        justify-content: center;
      }
      h2 {
        font-size: 1.25rem;
        margin: 0 0 0.9375rem 0;
      }
      p {
        max-width: 22.5rem;
        font-size: 0.875rem;
      }
      ul {
        font-size: 0.875rem;
        line-height: 2.3;
        margin-bottom: 3.75rem;
      }
      .col-left {
        flex: 0 0 26.25rem;
        padding-right: 3.125rem;
      }
      .col-right {
        flex: 0 0 26.25rem;
        padding-left: 3.125rem;
      }
    }
  }
  .questions {
    background-color: $cinder;
    color: $white;
    text-align: center;
    padding-bottom: 4.25rem;
    h3 {
      font-family: $font-family-sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.125rem;
      color: $white;
      text-transform: uppercase;
      margin: 3.75rem 0 0.625rem 0;
    }
    h2 {
      font-size: 1.125rem;
      font-family: $font-family-sans-serif;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.1875rem;
      color: $white;
      text-transform: uppercase;
      margin: 2.1875rem 0 1.25rem;
    }
    p {
      font-size: 1rem;
      font-family: $font-family-sans-serif;
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: 2.1875rem;
      color: $white;
    }
    .btn {
      width: 100%;
      max-width: 275px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 3.75rem;
      white-space: nowrap;
    }
    .row > div:first-of-type {
      position: relative;
      h2 {
        margin-top: 0;
      }
      &:after {
        content: "";
        display: block;
        width: 5rem;
        height: 2px;
        position: absolute;
        bottom: 0;
        right: calc(50% - 2.5rem);
        background-color: rgba($white, 0.35);
      }
    }
    @media (min-width: $media-breakpoint-md) {
      padding-top: 6.25rem;
      padding-bottom: 6.25rem;
      h3 {
        margin: 0 0 0.625rem 0;
      }
      h2 {
        margin: 1.5625rem 0 1.5625rem 0;
      }
      p {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
      }
      .row > div:first-of-type {
        position: relative;
        h2 {
          margin-top: 0;
        }
        &:after {
          content: "";
          display: block;
          width: 2px;
          height: 6.875rem;
          position: absolute;
          top: calc(50% - 6.25rem);
          bottom: auto;
          right: 0;
          background-color: rgba($white, 0.35);
        }
      }
    }
  }
  &.modal-dialog {
    .modal-content {
      border-radius: 0;
    }
    .modal-header {
      border-bottom: 0;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 0;
      button.close {
        color: $cinder;
        font-size: 1.875rem;
        float: none;
        opacity: 1;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .modal-body {
      text-align: left;
      padding-top: 0;
      padding-bottom: 2.5rem;
      h2 {
        margin-top: 0;
        margin-bottom: 2.25rem;
        font-family: $font-family-sans-serif;
        font-weight: 600;
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        color: $cinder;
        padding: 0 2.5rem;
      }
      h4 {
        font-family: $font-family-sans-serif;
        font-weight: 600;
        font-size: 0.875rem;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        color: $cinder;
        margin: 0 0 0.9375rem 0;
        padding: 0 2.5rem;
      }
      ol {
        font-family: $font-family-sans-serif;
        font-weight: 300;
        font-size: 0.875rem;
        color: $cinder;
        list-style-position: inside;
        padding-right: 2.5rem;
        padding-left: 3.75rem;
        margin-bottom: 2.5rem;
        list-style: none;
        counter-reset: my-awesome-counter;
        li {
          counter-increment: my-awesome-counter;
          position: relative;
          line-height: 2;
          &::before {
            content: counter(my-awesome-counter) ". ";
            color: $cinder;
            font-family: $font-family-sans-serif;
            font-weight: 600;
            position: absolute;
            left: -1.25rem;
          }
        }
      }
      .btn-cta {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  @media (min-width: $media-breakpoint-md) {
    &.modal-dialog {
      .modal-body {
        .btn-cta {
          max-width: calc(100% - 5rem);
        }
      }
    }
  }
}
