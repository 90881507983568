.locations-wrapper {
  padding: 14px 28px 48px 28px;
  a {
    color: $dark;
  }
  .store-locator-container {
    max-width: 1280px;
    margin: auto;
  }
  .body-sm {
    display: inline-flex;
    padding-bottom: 20px;
  }
  .slash {
    margin: 0 8px 0 8px;
  }
  .breadcrumbs {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
  .store-locator-form-wrapper {
    max-width: 800px;
    margin: auto;
  }
  .location-search-wrapper {
    margin-top: 27px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 768px) {
      display: block;
      margin-top: 21px;
    }
  }
  .location-search-input {
    flex-grow: 1;
  }
  button.location-search-button {
    width: 196px;
    min-width: 0;
    @media (max-width: 768px) {
      width: -webkit-fill-available;
      margin-top: 24px;
    }
  }
  .virtual-appt-link {
    font-size: 15px;
    margin-top: 25px;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .helpful-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    padding: 48px 0 48px 0;
    margin: 48px -32px 0;
    a {
      margin: 16px;
      color: #191c1f;
      &:hover {
        text-decoration: none;
      }
    }

    h3 {
      margin: 3px;
      padding-right: 5px;
      padding-bottom: 7px;
    }
  }
  .all-locations {
    padding-top: 48px;
    max-width: 1280px;
    margin: auto;
    h3 {
      margin: 0;
      padding-bottom: 20px;
    }
  }
  .all-locations-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    height: 500px;
    max-width: 1300px;
    padding: 0;
    margin: 0;
    display: flex;

    .h3 {
      font-size: 18px;
      line-height: 28.8px;
      font-weight: 500;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .city-name {
      padding-bottom: 8px;
      a {
        font-size: 15px;
        line-height: 24px;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $dark;
          text-decoration: none;
        }
      }
    }
  }

  // FormAutocomplete => resolves bottom border issue
  .form-autocomplete {
    input {
      height: 3.16rem !important;
    }
  }

  // nearest store
  .nearest-store-wrapper {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 1.5rem;
    padding-top: 48px;
    padding-bottom: 64px;
    max-width: 1280px;
    margin: auto;
    .nearest-location-heading {
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
    }
    .nearest-store-type {
      font-size: 12.5px;
      line-height: 20px;
      color: #595959;
      margin-bottom: -10px;
      margin: 0;
    }
    .appointment-disclaimer-text {
      color: #d10c7f;
      font-size: 12.5px;
      font-weight: 500;
      display: inline;
      position: absolute;
      margin-top: 10px;
      margin-left: 3px;
    }
    .nearest-store-address {
      padding-top: 17px;
    }
    .nearest-store-link {
      text-decoration: underline;
      color: $dark;
      font-weight: 300;
      font-size: 15px;
      &:hover {
        color: $dark;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .nearest-location-heading-hours {
      padding-top: 32px;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      margin: 0;
    }
    .special-hours-text {
      margin-top: 16px;
      font-size: 12.5px;
      line-height: 20px;
      color: #d10c7f;
      flex-shrink: 1;
      width: 100%;
      background-color: #f0f1f4;
      padding: 16px 16px 16px 16px;
      margin: 0;
      @media (max-width: 768px) {
        .special-hours-breakline {
          display: none;
        }
      }
    }
    .hours-container {
      display: flex;
      margin-bottom: -25px;
    }
    .days-of-operations {
      width: 120px;
      margin-right: 64px;
      display: inline-block;
    }
    .hours-of-operations {
      position: relative;
      display: inline-block;
    }
  }
  .neiman-marcus-copy {
    font-size: 12.5px;
    margin-top: 16px;
    margin-bottom: -15px;
  }
  // wrap google maps div
  .map-container {
    margin-top: 40px;
    height: 500px;
    width: 100%;
    position: relative;
  }

  // breakline for schedule VA text
  .virtual-appointment-breakline {
    display: none;
  }
  @media (max-width: 375px) {
    .virtual-appointment-breakline {
      display: initial;
    }
  }

  .services-header {
    font-size: 18px;
    line-height: 31px;
    margin-bottom: 32px;
  }
  // entire services + additional stores div
  .services-container {
    max-width: 1280px;
    margin: auto;
  }

  .tileImage {
    text-decoration: none;
    .services-item-img {
      margin-bottom: 10px;
      width: 100%;
    }
    cursor: initial;
    &.active {
      .services-item-img {
        transition: transform 0.3s ease-in-out;
      }
      &:hover,
      &:focus {
        text-decoration: underline;
        cursor: pointer;
        .services-item-img {
          transform: scale(1.05);
        }
      }
    }
  }

  .services-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;

    .services-item-header {
      font-size: 15px;
      line-height: 25px;
      font-weight: 500;
    }
    .services-item-description {
      font-size: 12.5px;
      line-height: 20px;
      margin-top: 5px;
    }
  }

  .border-wrapper {
    border-top: 1px solid $gray-400;
    margin-top: 60px;
    margin-bottom: 48px;
  }

  .additional-stores-header {
    font-size: 18px;
    line-height: 31px;
    margin-bottom: 16px;
  }
  .additional-stores-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    h2 {
      margin-top: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
  .additional-stores-wrapper > div {
    background-color: #f0f1f4;
    padding: 2rem;
  }
  .additional-stores-wrapper > div:nth-child(odd) {
    background-color: #f0f1f4;
  }
  .nearby-locations-link {
    text-decoration: none;
    &:hover {
      color: $dark;
      text-decoration: underline;
      cursor: pointer;
    }
    .additional-store-type {
      font-size: 12.5px;
      color: $gray-700;
      margin-top: -17.5px;
      line-height: 20px;
    }
  }
  .additional-stores-loader-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  .additional-stores-loader-wrapper > div {
    background-color: #ddd;
    padding: 1rem;
  }
  .additional-stores-loader-wrapper > div:nth-child(odd) {
    background-color: #ddd;
  }

  // breakpoint-lg
  @media (max-width: 993px) {
    .services-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .map-container {
      height: 300px;
    }
    .helpful-links {
      h3 {
        font-size: 18px;
      }
    }
    .store-locator-form-wrapper {
      max-width: 548px;
    }
  }

  // breakpoint-md
  @media (max-width: $media-breakpoint-sm) {
    padding: 14px 24px 64px 24px;
    .all-locations-wrapper {
      max-width: 700px;
      height: 640px;
      .h3 {
        font-size: 15px;
      }
    }
    .store-locator-form-wrapper {
      max-width: 468px;
      width: 100%;
      margin: auto;
    }
    .nearest-store-wrapper {
      width: 462px;
      margin: auto;
    }
    .nearest-store-wrapper {
      grid-template-columns: 1fr;
    }
    .services-wrapper {
      grid-template-columns: 1fr 1fr;
    }
    .additional-stores-wrapper {
      grid-template-columns: 1fr;
    }
  }

  // breakpoint mobile
  @media (max-width: 540px) {
    .store-locator-form-wrapper {
      margin: auto;
    }
    .store-locator-container {
      width: auto;
      margin: auto;
    }
    .nearest-store-wrapper {
      width: 100%;
      margin: auto;
    }
    .nearest-store-wrapper {
      grid-template-columns: 1fr;
    }
    .services-wrapper {
      grid-template-columns: 1fr;
    }
    .additional-stores-wrapper {
      grid-template-columns: 1fr;
    }
    .days-of-operations {
      margin-right: 20px !important;
      display: inline-block;
    }
  }

  @media (max-width: 390px) {
    padding: 12px 20px 26px 20px;
    .body-sm {
      margin-left: -10px;
    }
    .store-locator-form-wrapper {
      margin: auto;
    }
    .virtual-appt-link {
      margin-top: 17px;
      line-height: 24px;
    }
    .helpful-links {
      padding-top: 23px;
      margin-top: 38px;
      padding-bottom: 38px;
      .h3 {
        font-size: 15px;
      }
    }
    .all-locations-wrapper {
      max-width: 340px;
      height: 640px;
      .h3 {
        font-size: 15px;
      }
    }
    .all-locations {
      padding-top: 41px;
      h3 {
        padding-bottom: 7px;
      }
    }
  }
  @media (max-width: 370px) {
    .store-locator-form-wrapper {
      margin: auto;
    }
  }

  .no-locations-detected {
    margin-top: 49px;
  }
  .no-stores-nearby-heading {
    font-size: 15px;
    font-weight: 500;
  }

  @media (max-width: 360px) {
    .helpful-links {
      a {
        margin: 5px;
      }
    }
  }
}
