//
// Color system
//

$white: #ffffff !default;
$gray-100: #f8f8f8 !default;
$gray-200: #e8e8e8 !default;
$gray-300: #d8d8d8 !default;
$gray-400: #c8c8c8 !default;
$gray-500: #adadad !default;
$gray-600: #7d7d7d !default;
$gray-700: #595959 !default;
$gray-800: #35383a !default;
$gray-900: #191c1f !default;
$black: #000000 !default;
$cinder: $gray-900;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #eb0c8f !default;
$red: #d9534f !default;
$orange: #f58720 !default;
$yellow: #ecb904 !default;
$green: #008300 !default;
$teal: #20c997 !default;
$cyan: #1f9bcf !default;
$solitude: #f0f1f4;
$lt-blue: $solitude;
$amour: #f3dce6;
$lt-pink: $amour;

$primary: $black !default;
$secondary: $white !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $pink !default;
$light: $white !default;
$dark: $gray-900 !default;

$yiq-contrasted-threshold: 185 !default;

// Options

$enable-rounded: false !default;

// Body

$body-color: $gray-900 !default;

// Components

// $border-radius:               0 !default;
// $border-radius-lg:            0 !default;
// $border-radius-sm:            0 !default;

// Fonts

$font-family-sans-serif: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-size-base: 1rem !default; // 16px
$font-size-lg: $font-size-base * 1.25 !default; // 20px
$font-size-sm: $font-size-base * 0.75 !default; // 12px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-base: $font-weight-light;

$h1-font-size: 1.625rem !default;
$h2-font-size: 1.35rem !default;
$h3-font-size: 1.125rem !default;
$h4-font-size: 0.9325rem !default;
$h5-font-size: 0.78125rem !default;
$h6-font-size: 0.64125rem !default;

$headings-font-weight: 500 !default;
$headings-color: $gray-900 !default;

// Tables

$table-border-color: rgba(0, 0, 0, 0.05) !default;

// Buttons + Forms

$input-btn-border-width: 1px !default;

// Buttons
$btn-font-family: $font-family-sans-serif;
$btn-line-height: 1.5rem !default;

$input-btn-padding-y: 0.875rem !default;
$input-btn-padding-x: 2rem !default;

$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 1rem !default;

$input-btn-padding-y-lg: 0.875rem !default;
$input-btn-padding-x-lg: 2rem !default;

$btn-font-weight: 600 !default;
// $btn-border-width

// Forms

$input-line-height: 1.5 !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-300 !default;

$input-group-addon-bg: $gray-300 !default;

// Modals

$modal-content-border-radius: 6px !default;

// Navbar

$navbar-padding-y: 1.5rem !default;

$navbar-dark-hover-color: $white !default;

$navbar-light-color: rgba($black, 0.3) !default;
$navbar-light-hover-color: $gray-900 !default;
$navbar-light-active-color: $gray-900 !default;

// Pagination

$pagination-border-color: transparent !default;

$pagination-hover-border-color: $pagination-border-color !default;

$pagination-disabled-border-color: $pagination-border-color !default;

// Breadcrumbs

$breadcrumb-bg: transparent !default;

//Reference for Comp Library Breakpoints
/*
$media-breakpoint-xs: 576px;
$media-breakpoint-sm: 768px;
$media-breakpoint-md: 992px;
$media-breakpoint-lg: 1200px;
$media-breakpoint-xl: 1599px;
*/